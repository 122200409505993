import type { JSX } from 'react';
import { ImageWithTextSideBySide } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type ImageWithTextSideBySideProps =
  SliceComponentProps<Content.ImageWithTextSideBySideSlice>;

const ImageWithTextSideBySideSlice = ({
  slice,
}: ImageWithTextSideBySideProps): JSX.Element => {
  return <ImageWithTextSideBySide {...slice} />;
};

export default ImageWithTextSideBySideSlice;
